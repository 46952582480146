<template>
    <Splide v-bind="$attrs" class="section-atricles" :options="options" aria-label="slider">
        <div class="article-card-wrapper splide__slide" v-for="item in randomItems">
            <ClientOnly fallbackTag="div">
                <div class="article-card">
                    <NuxtLink :to="item.url">
                        <div class="img-wrapper">
                            <NuxtImg :src="item.imgSet.imageUrl" :alt="item.imgSet.imageAlt" loading="lazy" width="311" height="131" />
                        </div>
                        <div class="body">
                            <p class="article-card-title">
                                {{ item.title }}
                            </p>
                            <span class="read-more">Read more</span>
                        </div>
                    </NuxtLink>
                </div>
            </ClientOnly>
        </div>
    </Splide>
</template>

<script lang="ts" setup>
    import { Splide } from '@splidejs/vue-splide';
    import type { MoreContentViewModel } from '@/types/models/store/more-content-view-model';

    const props = defineProps({
        options: {
            type: Object,
            default: {
                perPage: 3,
                perMove: 3,
                rewind: true,
                gap: '3rem',
                arrowPath: 'M37.9318 23.6279L14.8053 0.565417C14.0476 -0.189756 12.8209 -0.188486 12.0644 0.569323C11.3086 1.32704 11.3105 2.55447 12.0683 3.31023L33.8182 25.0001L12.0676 46.6898C11.3098 47.4457 11.3079 48.6724 12.0636 49.4302C12.4428 49.81 12.9396 50 13.4364 50C13.9319 50 14.4267 49.8113 14.8052 49.4341L37.9318 26.3722C38.2967 26.0091 38.5015 25.5149 38.5015 25.0001C38.5015 24.4852 38.2962 23.9917 37.9318 23.6279Z',
                padding: {
                    left: '2rem',
                    right: '2rem',
                },
                breakpoints: {
                    1200: {
                        perPage: 2,
                        perMove: 2
                    },
                    990: {
                        perPage: 1,
                        perMove: 1
                    },
                }
            }
        },

        items: {
            type: Array as PropType<MoreContentViewModel[]>,
            required: true
        }
    });

    const randomItems = computed(() => {
        return [...props.items].sort(() => Math.random() - 0.5);
    });

    defineOptions({
        inheritAttrs: false
    })
</script>

<style lang="scss">
    @import '@splidejs/vue-splide/css';
    @import '@/assets/styles/_splide.scss';
</style>